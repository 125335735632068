<template>
  <div :class="inputClass" class="flex">
    <a-input
      ref="inputRef"
      v-bind="inputProps"
      :class="['df-input-inner']"
      @focus="handleFocus"
      @blur="handleBlur"
      @input="handleInput"
      @change="handleChange"
      @press-enter="handlePressEnter"
    >
      <template v-if="$slots.prefix" #prefix>
        <slot name="prefix"></slot>
      </template>
      <template v-if="$slots.suffix" #suffix>
        <slot name="suffix"></slot>
      </template>
    </a-input>
  </div>
</template>

<script setup lang="ts">
import type { InputProps } from 'ant-design-vue';
import type { ChangeEvent } from 'ant-design-vue/es/_util/EventInterface';
import { computed, ref } from 'vue';

interface Props extends InputProps {
  size?: 'large' | 'middle' | 'small';
}

const props = withDefaults(defineProps<Props>(), {
  size: 'large',
});

const inputProps = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onFocus, onBlur, onInput, onChange, onPressEnter, ...rest } = props;
  return rest;
});

const emit = defineEmits<{
  (event: 'update:value', value: string): void;
  (event: 'change', e: ChangeEvent): void;
  (event: 'blur', e: FocusEvent): void;
  (event: 'pressEnter', e: KeyboardEvent): void;
}>();

const inputRef = ref();
const focused = ref(false);

const inputClass = computed(() => {
  return [
    'df-input',
    `df-input-${props.size}`,
    {
      'df-input-focused': focused.value,
      'df-input-disabled': props.disabled,
    },
  ];
});

const handleFocus = (e: FocusEvent) => {
  focused.value = true;
  props.onFocus?.(e);
};

const handleBlur = (e: FocusEvent) => {
  focused.value = false;
  props.onBlur?.(e);
};

const handleInput = (e: Event) => {
  const value = (e.target as HTMLInputElement).value;
  props.onInput?.(e as ChangeEvent);
  emit('update:value', value);
};

const handleChange = (e: Event) => {
  const changeEvent = e as ChangeEvent;
  emit('change', changeEvent);
};

const handlePressEnter = (e: KeyboardEvent) => {
  emit('pressEnter', e);
};

defineExpose({
  focus: () => inputRef.value?.focus(),
  blur: () => inputRef.value?.blur(),
});
</script>

<style lang="less" scoped>
.df-input {
  position: relative;
  width: 100%;

  :deep(.ant-input),
  :deep(.ant-input-affix-wrapper) {
    font-family: Inter;
    background-color: #f0f0f5;
    border: 1px solid transparent;
    transition: all 0.3s;

    &::placeholder {
      color: #848b96;
    }

    &:hover {
      background-color: #f0f0f5;
      background-image: linear-gradient(rgba(73, 79, 135, 0.05), rgba(73, 79, 135, 0.05));
    }

    &.ant-input-affix-wrapper-focused {
      background-color: #ffffff;
      border: 1px solid #070b10;
      background-image: none;
    }

    &.ant-input-status-error,
    &.ant-input-affix-wrapper-status-error {
      background-color: #ffffff;
      border: 1px solid #fa2730;
    }
  }

  :deep(.ant-input-affix-wrapper) {
    .ant-input {
      background-color: transparent;
      border: none;

      &:hover {
        background-color: transparent;
        background-image: none;
      }
    }

    .ant-input-show-count-suffix {
      color: #b3b9c2;
    }
  }

  &-large {
    :deep(.ant-input),
    :deep(.ant-input-affix-wrapper-lg) {
      height: 100%;
      padding: 10px 12px;
      font-size: 14px;
      line-height: 1.4285714285714286;
      border-radius: 8px;
    }
    :deep(.ant-input-prefix) {
      padding-left: 12px;
      margin-right: -5px;
    }
    :deep(.ant-input-suffix) {
      padding-right: 12px;
    }
    :deep(.ant-input-affix-wrapper-lg) {
      height: 40px;
      padding: 0;
      .ant-input {
        padding: 0 12px;
      }
    }
  }

  &-middle {
    :deep(.ant-input),
    :deep(.ant-input-affix-wrapper) {
      height: 32px;
      padding: 6px 8px;
      font-size: 12px;
      line-height: 1.3333333333333333;
      border-radius: 6px;
    }
    :deep(.ant-input-prefix) {
      padding-left: 8px;
      margin-right: -4px;
    }
    :deep(.ant-input-suffix) {
      padding-right: 8px;
    }
    :deep(.ant-input-affix-wrapper) {
      padding: 0;
      .ant-input {
        // padding: 0;
        padding: 0 8px;
      }
    }
  }

  &-small {
    :deep(.ant-input),
    :deep(.ant-input-affix-wrapper-sm) {
      height: 24px;
      padding: 6px 4px;
      font-size: 12px;
      line-height: 1.3333333333333333;
      border-radius: 4px;
    }
    :deep(.ant-input-prefix) {
      padding-left: 4px;
      margin-right: -2px;
    }
    :deep(.ant-input-suffix) {
      padding-right: 4px;
    }
    :deep(.ant-input-affix-wrapper-sm) {
      padding: 0;
      .ant-input {
        padding: 0 4px;
      }
    }
  }

  &-focused {
    :deep(.ant-input),
    :deep(.ant-input-affix-wrapper) {
      background-color: #ffffff;
      border: 1px solid #070b10;
    }
  }

  &-disabled {
    :deep(.ant-input),
    :deep(.ant-input-affix-wrapper) {
      color: #070b10;
      opacity: 0.4;
      cursor: not-allowed;

      &:hover {
        background-image: none;
      }
    }
  }
}
</style>
